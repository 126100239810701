@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #f5f5f5;
}
body {
  height: fit-content;
}
.image-container {
  width: 160px;
  height: 160px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow-y: hidden !important;
}
.image-container img {
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
  background-image: inherit; */
  /* object-fit: contain; Add this to scale the image */
  border: 4px solid #fff;
  width: 160px;

  /* height: 100%; */
}
.chart-wrapper {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
