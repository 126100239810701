.myDiv {
  position: relative;
}

.myDiv::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 87, 34, 0.3), rgba(255, 87, 34, 0.3));
}
